<template>
  <div>
    <b-card-actions
      title="Live Attendance"
      action-refresh
      actionCollapse
      v-if="getRole() != 'director'"
    >
      <b-img
        :src="liveAttendance.photo"
        alt="profile"
        thumbnail
        width="140"
        height="140"
        left
        class="mr-1 mb-2"
      >
      </b-img>
      <h3>{{ liveAttendance.name }}</h3>
      <b-row class="mt-1">
        <b-col md="3">
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Working From</h5>
            <span>{{ liveAttendance.working_from }}</span>
          </div>
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Clock In Time</h5>
            <span>{{ momentTime(liveAttendance.clock_in_time) }}</span>
          </div>
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Late</h5>
            <span
              >{{
                liveAttendance.late != "" ? liveAttendance.late : 0
              }}
              Hours</span
            >
          </div>
        </b-col>
        <b-col md="3">
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Break In Time</h5>
            <span>
              {{
                liveAttendance.break_in != "" ? liveAttendance.break_in : "-"
              }}
            </span>
          </div>
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Break Out Time</h5>
            <span>
              {{
                liveAttendance.break_out != "" ? liveAttendance.break_out : "-"
              }}
            </span>
          </div>
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Break Hours</h5>
            <span>
              {{
                liveAttendance.break_hours != ""
                  ? liveAttendance.break_hours
                  : 0
              }}
              Hours
            </span>
          </div>
        </b-col>
        <b-col md="3">
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Clock Out From</h5>
            <span>
              {{
                liveAttendance.clock_out_addres != ""
                  ? liveAttendance.clock_out_addres
                  : "-"
              }}
            </span>
          </div>
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Clock Out Time</h5>
            <span>{{ momentTime(liveAttendance.clock_out_time) }}</span>
          </div>
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Work Hours</h5>
            <span>
              {{
                liveAttendance.work_hours != "" ? liveAttendance.work_hours : 0
              }}
              Hours
            </span>
          </div>
        </b-col>
        <b-col md="3">
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Totally</h5>
            <span>
              {{ liveAttendance.Totally != "" ? liveAttendance.Totally : 0 }}
              Hours
            </span>
          </div>
          <div style="line-height: 5px; margin-bottom: 1rem">
            <h5>Overtime</h5>
            <span>
              {{ liveAttendance.Overtime != "" ? liveAttendance.Overtime : 0 }}
              Hours
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-row>
      <b-col md="3">
        <b-card title="Filters">
          <div>
            <b-form @submit.prevent="applyFilters()">
              <b-form-group
                label="Employee"
                v-if="getRole() == 'manager' || getRole() == 'director'"
              >
                <v-select
                  id="employee"
                  v-model="fEmployee"
                  label="name"
                  :options="fEmployeeList"
                />
              </b-form-group>
              <b-form-group label="From">
                <month-picker-input
                  v-model="fFrom"
                  :no-default="true"
                  :editable-year="true"
                  @change-year="fFromYearChange"
                ></month-picker-input>
              </b-form-group>
              <div class="text-right mt-2">
                <b-button type="button" variant="primary mr-1" size="sm">
                  Reset
                </b-button>
                <b-button type="submit" variant="primary" size="sm">
                  Apply
                </b-button>
              </div>
            </b-form>
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <b-card-actions
          title="Attendances Report"
          action-refresh
          ref="mainCard"
          @refresh="fetchData"
        >
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Sort</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-100"
              />

              <download-excel
                :data="exportItems"
                :fields="exportFields"
                :name="`Attendance Report`"
                :worksheet="'Attendance Report'"
                class="cursor-pointer"
              >
                <b-button variant="" size="sm" class="d-flex ml-1 export-btn">
                  <feather-icon
                    icon="FileIcon"
                    size="14"
                    class="text-success"
                  />
                  <label
                    style="font-size: 14px; margin-left: 5px; cursor: pointer"
                  >
                    Export
                  </label>
                </b-button>
              </download-excel>
            </div>
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Search</label>
              <b-form-input
                id="searchInput"
                v-model="filter"
                size="sm"
                type="search"
                placeholder="Search"
              />
            </div>
          </div>
          <b-row>
            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                @filtered="onFiltered"
              >
                <template #cell(employee)="data">
                  <span
                    @click="showDetail(data.value.data)"
                    class="text-primary"
                  >
                    {{ data.value.name }}
                  </span>
                </template>
                <template #cell(profile_photo_url)="data">
                  <b-avatar :src="data.value" />
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  Showing
                  {{ perPage > totalRows ? totalRows : perPage }} of
                  {{ totalRows }} entries
                </span>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  class="my-0"
                  @change="handlePageChange"
                />
              </div>
            </b-col>
          </b-row>

          <b-modal
            @shown="refresh"
            id="detail"
            size="lg"
            hide-footer
            v-if="showModal"
          >
            <div class="text-center mb-3" style="line-height: 1px">
              <h1 class="mb-2">Attendance</h1>
              <span>
                This is location for employee name <b>{{ detail.user.name }}</b>
              </span>
            </div>
            <div>
              <l-map :zoom="zoom" :center="[detail.lang, detail.longt]">
                <l-tile-layer :url="url" />
                <l-marker :lat-lng="[detail.lang, detail.longt]" :icon="icon">
                  <l-popup>{{ detail.user.name }} here!</l-popup>
                </l-marker>
              </l-map>
            </div>
          </b-modal>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.month-picker__container {
  z-index: 9999 !important;
}

.table thead th {
  white-space: nowrap;
}

.month-picker-input-container {
  width: 100% !important;
}

.month-picker-input-container .month-picker-input {
  width: 100% !important;
  padding: 0.78em;
}

.btn-outline-secondary {
  border-color: #e4e7ea;
  color: #5a8dee;
  background-color: #fff;
  border-radius: 0.25rem;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
/* eslint-disable global-require */
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { latLng, icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { MonthPickerInput } from "vue-month-picker";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    MonthPickerInput,
  },
  data() {
    return {
      filter_employee: null,
      employees: [],
      detail: {},
      showModal: false,

      month_year: null,
      month: null,
      year: null,

      filter_department: null,
      departments: [],

      // table
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "profile_photo_url",
          label: "Avatar",
        },
        {
          key: "employee",
          label: "Employee",
        },
        { key: "clock_in", label: "Clock In", sortable: true },
        { key: "late", label: "Late", sortable: true },
        { key: "working_from", label: "Working From", sortable: "true" },
        { key: "break_in", label: "Break In", sortable: "true" },
        { key: "break_out", label: "Break Out", sortable: "true" },
        { key: "break_hours", label: "Break Hours", sortable: "true" },
        { key: "clock_out", label: "Clock Out", sortable: "true" },
        {
          key: "clock_out_address",
          label: "Clock Out Address",
          sortable: true,
        },
        { key: "work_hours", label: "Work Hours", sortable: true },
        { key: "overtime", label: "Overtime", sortable: true },
        { key: "totally", label: "Totally", sortable: true },
      ],
      items: [],

      // export excel
      exportFields: {
        "Employee Name": "employee",
        "Clock In": "clock_in",
        Late: "late",
        "Working From": "working_from",
        "Break In": "break_in",
        "Break Out": "break_out",
        "Break Hours": "break_hours",
        "Clock Out": "clock_out",
        "Clock Out From": "clock_out_address",
        "Work Hours": "work_hours",
        Overtime: "overtime",
        Totally: "totally",
      },
      exportItems: [],

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 50,
      center: ["-6.2399957", "106.9214024"],
      markerLatLng: ["-6.2399957", "106.9214024"],
      icon: icon({
        iconUrl: require("@/assets/images/icons/loc.png"),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      liveAttendance: [],
      fEmployee: "",
      fEmployeeList: [],
      fFrom: "",
      filters: {},
    };
  },
  mounted() {
    this.fetchData();

    if (this.getRole() != "director") {
      this.fetchNow();
    }

    this.callApi({
      url: "users/getAll",
      success: (res) => {
        res.result
          .filter((f) => f.id != 1)
          .map((item) => {
            this.fEmployeeList.push(item);
          });
      },
    });
  },
  methods: {
    refresh() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 0);
    },

    fFromYearChange(year) {
      this.fFrom.year = year;
    },

    applyFilters() {
      if (this.fEmployee != "" && this.fEmployee != null) {
        this.filters.user_id = this.hashid(this.fEmployee.id);
      }
      if (this.fFrom != "") {
        this.filters.month = this.fFrom.monthIndex;
        this.filters.year = this.fFrom.year;
      }
      this.fetchData(this.filters);
    },

    handlePageChange(data) {
      this.fetchData({}, data);
    },

    showDetail(data) {
      this.showModal = true;
      this.detail = data;
      this.$bvModal.show("detail");
    },

    momentTime(date) {
      if (date != "") {
        return moment(date).format("h:mm a");
      }
      return "-";
    },

    fetchNow() {
      this.callApi({
        url: "attendances/getDataSpreadsheet",
        method: "GET",
        params: {
          user_id: this.hashid(this.getAuthId()),
        },
        success: (res) => {
          res.result.map((item) => {
            if (item.user_id == this.getAuthId()) {
              this.liveAttendance = item;
            }
          });
        },
      });
    },

    fetchData(filter = {}, page = null) {
      if (filter != {} && page == null) {
        this.items = [];
      }

      this.exportItems = [];
      this.$refs["mainCard"].showLoading = true;

      let params = {};
      params.month = this.filters.month;
      params.year = this.filters.year;
      params.user_id = this.filters.user_id;
      params.limit = this.totalRows > 1 ? this.totalRows : 100;

      if (this.getRole() != "director" && this.getRole() != "manager") {
        params.user_id = this.hashid(this.getAuthId());
      }

      if (page != null) {
        params.page = page;
      }

      this.callApi({
        method: "GET",
        url: "/attendances/fetchAll",
        params: params,
        success: (response) => {
          response.result.data.map((item) => {
            this.items.push({
              employee: { data: item, name: item.user.name },
              profile_photo_url: item.user.profile_photo_path,
              clock_in: moment(item.clock_in_time)
                .locale("id")
                .format("YYYY/MM/DD HH:mm:ss"),
              late: `${item.late != null ? item.late : 0} Hours`,
              working_from: this.truncateText(item.working_from, 20),
              break_in: item.break_in != "" ? item.break_in : "-",
              break_out: item.break_out != "" ? item.break_out : "-",
              break_hours: `${
                item.break_hours != "" ? item.break_hours : 0
              } Hours`,
              clock_out:
                item.clock_out_time != ""
                  ? moment(item.clock_out_time)
                      .locale("id")
                      .format("YYYY/MM/DD HH:mm:ss")
                  : "-",
              clock_out_address:
                item.clock_out_addres != ""
                  ? this.truncateText(item.clock_out_addres, 20)
                  : "-",
              work_hours: `${
                item.work_hours != null ? item.work_hours : 0
              } Hours`,
              overtime: `${item.Overtime != null ? item.Overtime : 0} Hours`,
              totally: `${item.Totally != "" ? item.Totally : 0} Hours`,
              lang: item.lang.replace(",", "."),
              longt: item.longt.replace(",", "."),
              // user: item.user,
            });

            this.exportItems.push({
              employee: item.user.name,
              clock_in: moment(item.clock_in_time)
                .locale("id")
                .format("YYYY/MM/DD HH:mm:ss"),
              late: `${item.late != null ? item.late : 0} Hours`,
              working_from: item.working_from,
              break_in: item.break_in != "" ? item.break_in : "-",
              break_out: item.break_out != "" ? item.break_out : "-",
              break_hours: `${
                item.break_hours != "" ? item.break_hours : 0
              } Hours`,
              clock_out:
                item.clock_out_time != ""
                  ? moment(item.clock_out_time)
                      .locale("id")
                      .format("YYYY/MM/DD HH:mm:ss")
                  : "-",
              clock_out_address:
                item.clock_out_addres != "" ? item.clock_out_addres : "-",
              work_hours: `${
                item.work_hours != null ? item.work_hours : 0
              } Hours`,
              overtime: `${item.Overtime != null ? item.Overtime : 0} Hours`,
              totally: `${item.Totally != "" ? item.Totally : 0} Hours`,
            });
          });
          this.totalRows = response.result.total;
          this.currentPage = response.result.current_page;
          this.$refs["mainCard"].showLoading = false;
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to fetch data",
              variant: "error",
            },
          });
          this.$refs["mainCard"].showLoading = false;
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    monthYearSelected() {
      this.month = this.month_year.month;
      this.year = this.month_year.year;
    },
  },
};
</script>